<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="384"
    height="256"
    viewBox="0 0 969.22 1300.06"
  >
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Слой_1" data-name="Слой 1">
        <g id="CHAIR">
          <ellipse
            class="cls-1"
            cx="204.28"
            cy="874.51"
            rx="169.38"
            ry="32.25"
          />
          <path
            class="cls-2"
            d="M194.26,906.77v226.61a66.3,66.3,0,0,1-37,59.45L97,1222.54a36.94,36.94,0,0,0-20.6,33.13"
          />
          <circle class="cls-3" cx="76.95" cy="1276.61" r="20.94" />
          <circle class="cls-3" cx="156.22" cy="1276.61" r="20.94" />
          <path
            class="cls-2"
            d="M194.26,1106.77v71a45.91,45.91,0,0,1-11.82,30.75l-14.39,16a45.9,45.9,0,0,0-11.83,30.75"
          />
          <path
            class="cls-2"
            d="M215.64,906.77v226.61a66.29,66.29,0,0,0,37,59.45l60.25,29.71a36.93,36.93,0,0,1,20.61,33.13"
          />
          <circle class="cls-3" cx="332.96" cy="1276.61" r="20.94" />
          <circle class="cls-3" cx="253.68" cy="1276.61" r="20.94" />
          <path
            class="cls-2"
            d="M215.64,1106.77v71a45.91,45.91,0,0,0,11.82,30.75l14.4,16a45.89,45.89,0,0,1,11.82,30.75"
          />
        </g>
        <g id="CHARACTER">
          <g id="LEGS">
            <g id="RIGHT">
              <path
                id="FOOT"
                class="cls-4"
                d="M360.06,1153.84s1.18,39.42-22.58,55.93c-22.4,15.56-23.23,28.08-23.23,28.08s37.33,36.25,46.52,40,42.66,15.3,60,15.3c0,0,11.73-8.55-15.13-32-36.44-31.89,4.91-89.18,4.91-89.18S372.57,1160.6,360.06,1153.84Z"
              />
              <path
                id="PANT"
                class="cls-5"
                d="M293.25,688s189.63,22.36,265.29,101.26c40.88,42.64-120,299.67-127.55,386,0,0-58.37-7.68-96.2-36.87,0,0,7-238.93,93-283.21,0,0-163.67,13-233.71-22.7s0-133.29,0-133.29S255,711.07,293.25,688Z"
              />
              <path
                id="SHOE"
                class="cls-6"
                d="M343.48,1200.77s-31.47,12.39-32.23,35.08c0,0,32.85,33.65,49.52,42,26.13,13.15,62,17.3,62,17.3s13-16-7.43-31.51-24.37-43-24.37-43-2.25,19.46-20.43,23c-16.28,3.2-21.54-5-21.54-5S353.9,1203.53,343.48,1200.77Z"
              />
            </g>
            <g id="LEFT">
              <path
                id="PANT-2"
                data-name="PANT"
                class="cls-7"
                d="M81.85,702.51s-34.42,97.42-22,124.62c23.12,50.8,206.88,65.94,343.08,41.07,0,0-47.57,167.51,8.64,304.79,0,0,37.84,6.53,90.8-1,0,0-1.08-165.39,22.7-341.58C529.8,795,326.08,711.87,236.39,702.48S81.85,702.51,81.85,702.51Z"
              />
              <path
                id="FOOT-2"
                data-name="FOOT"
                class="cls-8"
                d="M425.05,1174.65s10.27,38.32,10.27,49.73c0,23.24-17.84,51.34-14.6,70.8H582.27s-9.78-23.85-49.13-32.49c-44.32-9.73-52.45-38.18-51.34-88C481.8,1174.65,452.6,1176.89,425.05,1174.65Z"
              />
              <path
                id="SHOE-2"
                data-name="SHOE"
                class="cls-9"
                d="M432.52,1242.73s-18.82,26.5-11.8,52.45H584.79s-.37-23.2-23.24-26-60-21.61-60-21.61,2.75,27.57-39.41,30.81C462.14,1278.42,463.77,1243.78,432.52,1242.73Z"
              />
            </g>
          </g>
          <g id="RIGHT_HAND" data-name="RIGHT HAND">
            <path
              class="cls-9"
              d="M317.31,299.82c-23.1,11.46-37.08,15.18-29.77,88.29S348,546.64,348,546.64l69.12-68.14S370.54,343,360.78,328C341.77,298.89,326.3,295.36,317.31,299.82Z"
            />
            <path
              class="cls-10"
              d="M436,500.48s-14.16-32-21.14-32.33-56.4,36.38-63.34,68.86c-2.23,10.48-12.6,28,47.11,35.63C417.14,575,436,500.48,436,500.48Z"
            />
            <path
              class="cls-4"
              d="M657,556.81s-4.42,2.88-17.47-.22c-4.2-1-10.13-8.38-16.38-12.39-5.06-3.25-15-2.69-25.69-1.42-6.06.7-11.78-7.31-15.61-11.81-38,11-143.87,41.8-185.94,38.31-10.68-.88,19.89-68.17,38.4-72.56,14-3.31,96.39-.36,148.62,6.31a356.47,356.47,0,0,1,54.88,12.4C644.67,519.75,657,556.81,657,556.81Z"
            />
          </g>
          <g id="SHIRT">
            <path
              class="cls-11"
              d="M293.25,688s-18.33,15.19-56.85,14.45v-.06c-4.34-73.54-13.38-211.69-22.81-263-18.89-102.69-70.39-191.24-71.19-192.61v0L241.12,252s51.16,36.17,62.33,187.48S293.25,688,293.25,688Z"
            />
            <path
              class="cls-9"
              d="M241.11,252c9.36,3.7,70.53,34.68,90,46.21s31.24,101.74,21,174.4c-17.29,122.5-15.85,170.41,1.28,225.89,0,0-39.05-8.17-60.12-9.86,0,0-10.73-84,4-169.19C327.11,346.65,241.11,252,241.11,252Z"
            />
            <path
              class="cls-9"
              d="M142.38,246.8s-97.6,32.28-129.72,68c-13,14.41,61.26,192.41,54.77,244.29S30.68,725.52,30.68,725.52,77,786.05,239.06,750c0,0-12.37-239.25-25.49-310.59C194.54,335.93,142.38,246.8,142.38,246.8Z"
            />
            <path
              class="cls-12"
              d="M348.76,412.07c-4.47,31.63-8.71,106-6.14,130.28"
            />
          </g>
          <g id="LEFT_HAND" data-name="LEFT HAND">
            <path
              class="cls-9"
              d="M34.13,296.86c-21.47,17.9-40.2,16.46-32.27,95.73S67.43,564.48,67.43,564.48l74.95-73.89S103.71,347,96.26,329.17C85.3,302.88,55.6,279,34.13,296.86Z"
            />
            <path class="cls-12" d="M143.42,490.59S119.59,375.27,103.52,351" />
            <path
              class="cls-10"
              d="M171.45,505.76s-24-26.08-31.59-26.39-59,36.51-70,74.2c-3.25,11.16-21.2,30.07,49.77,48.28C159.1,612,171.45,505.76,171.45,505.76Z"
            />
            <path
              class="cls-8"
              d="M477.08,554s-3.68,5.14-9.05,1.61c-3.35-2.2-9.42-8-14-10.87a226.89,226.89,0,0,1,11.29,20,50.62,50.62,0,0,1-18.78,4.3c-5.36.23-13.59-9-21.84-13.16-6.67-3.35-18.85-1.36-31.91,1.6s-27.13-16.37-27.13-16.37V541c-24.78,8.35-183.94,53-243.18,49.88-13.29-.7,22.13-79.78,44.94-85.92,19.26-5.19,145.34-3.86,206.22,4.78,23.49,2.65,59.24,12.26,65.94,13.78,6.11.83,20.18,10.85,24.09,14.09C467.75,541,477.08,554,477.08,554Z"
            />
            <path
              class="cls-13"
              d="M458.53,564.93s-16.56-23.77-21.12-25.4c-8-2.88-18.49-6.69-18.49-6.69"
            />
            <path
              class="cls-13"
              d="M450.6,567.17s-15-19.82-19.5-21.45c-8-2.88-18.5-6.69-18.5-6.69"
            />
          </g>
          <g id="HEAD">
            <g id="NECK">
              <path
                class="cls-8"
                d="M242.79,239.09l-22.61,63.58s-61.48,6.77-77.55-56.1l21.66-62Z"
              />
              <path
                class="cls-4"
                d="M242.8,239.09l-10.22,28.72c-13.6,6.19-31,6-48-16.59-15.41-20.52-18.73-50.63-21.64-61.8l1.31-4.81Z"
              />
            </g>
            <path
              id="HEAD-2"
              data-name="HEAD"
              class="cls-8"
              d="M164.29,184.61s18.86,43.31,41.91,50.3c22,6.66,48.91,4.89,51.7,0s25.62-62.62,19.56-100.76c-4.19-26.39-15.37-30-36.33-27.95s-28.33,4.36-53.95-3.49c-7.19-2.2,5.16,38.14-16.49,53.51,0,0-16.24-5.46-19.74.13S164.29,184.61,164.29,184.61Z"
            />
            <g id="HAIR">
              <path
                class="cls-7"
                d="M161.35,192.6S106,158.78,108.68,113c1.17-19.6,42.68-72.3,68.11-74.48s64.67,10.9,77,21.8S283,82.94,278.6,124.68a26.45,26.45,0,0,1-1.62,6.66c-4.54-23.84-15.65-27.16-35.84-25.14-21,2.1-28.34,4.35-54-3.49-7.19-2.21,5.17,38.14-16.49,53.51,0,0-16.24-5.46-19.73.12s13.33,28.26,13.33,28.26Z"
              />
              <path
                class="cls-7"
                d="M157.09,47.14S162.26,6.57,135.37,8,92.13,34.89,89.6,51.52c-3.24,21.37,35.55,27.67,35.55,27.67Z"
              />
              <path
                class="cls-14"
                d="M98.78,78.86c9.11,10.76,26.37.33,26.37.33"
              />
              <path
                class="cls-14"
                d="M164.51,45.57S170.44.51,139.59,2,88.66,40.39,92.83,51.82s35,27.37,35,27.37Z"
              />
              <path
                class="cls-14"
                d="M262,106c-5.47-2.18-12.56,3-20.87,5-27,6.5-45.54,3-55.55-9.89"
              />
            </g>
            <g id="GLASSES">
              <circle class="cls-3" cx="217.05" cy="162.47" r="21.05" />
              <ellipse
                class="cls-3"
                cx="271.36"
                cy="162.47"
                rx="19.71"
                ry="21.05"
              />
              <line
                class="cls-3"
                x1="237.95"
                y1="161.02"
                x2="250.82"
                y2="161.02"
              />
            </g>
          </g>
        </g>
        <g id="COMPUTER">
          <polyline
            class="cls-15"
            points="690.61 1297.56 780.61 611.16 796.83 611.16 886.83 1297.56"
          />
          <path
            class="cls-16"
            d="M849.47,580.33h-45l-7-9.88c-6.85-9.71-19.53-15.69-33.26-15.69H698.14c-13.73,0-26.41,6-33.26,15.69l-7,9.88H614.63l17.29-21.74c7.73-9.72,22-15.7,37.48-15.7H794.69c15.47,0,29.77,6,37.49,15.7Z"
          />
          <path
            class="cls-17"
            d="M850.3,580.33H832.05l-7-9.88c-6.86-9.71-19.54-15.69-33.26-15.69H699c-13.72,0-26.4,6-33.26,15.69l-7,9.88H615.46l17.29-21.74c7.73-9.72,22-15.7,37.49-15.7H795.53c15.47,0,29.76,6,37.48,15.7Z"
          />
          <path
            class="cls-17"
            d="M875.56,481.65H451.92c-11.25,0-19.36-11.18-16.24-22.39l63.19-226.64a28,28,0,0,1,26.79-20.88H949.3c11.25,0,19.37,11.18,16.24,22.38L902.35,460.77A28.05,28.05,0,0,1,875.56,481.65Z"
          />
          <path
            class="cls-18"
            d="M628.93,412.68l-10.69,65.56h37l-16.37-65.67C638.51,411.1,629.17,411.19,628.93,412.68Z"
          />
          <path
            class="cls-16"
            d="M779.33,542.2h-105L648.4,438c-2.86-11.45-6.56-15.91-15.42-18.43v-6H722.1A32.23,32.23,0,0,1,753.37,438Z"
          />
          <path
            class="cls-17"
            d="M789.75,542.2h-105L658.82,438c-3.58-14.35-8.46-24.43-23.26-24.43h97A32.25,32.25,0,0,1,763.79,438Z"
          />
          <polygon
            class="cls-19"
            points="518.16 562.43 378.59 570.44 378.59 580.33 518.16 580.33 518.16 562.43"
          />
          <rect
            class="cls-16"
            x="518.16"
            y="562.43"
            width="87.28"
            height="17.52"
          />
          <polygon
            class="cls-19"
            points="228.19 609.68 868.96 609.68 868.96 580.33 226.74 580.33 228.19 609.68"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.cls-1,
.cls-11,
.cls-17,
.cls-19 {
  fill: #fff;
}
.cls-1,
.cls-16,
.cls-17,
.cls-19,
.cls-2 {
  stroke: #6562cf;
}
.cls-1,
.cls-12,
.cls-13,
.cls-14,
.cls-15,
.cls-17,
.cls-2,
.cls-3 {
  stroke-miterlimit: 10;
}
.cls-1,
.cls-15,
.cls-2,
.cls-3 {
  stroke-width: 5px;
}
.cls-12,
.cls-13,
.cls-14,
.cls-15,
.cls-2,
.cls-3 {
  fill: none;
}
.cls-15,
.cls-3 {
  stroke: #ffb000;
}
.cls-4 {
  fill: #ffc0b7;
}
.cls-5 {
  fill: #3c4564;
}
.cls-6 {
  fill: #5654b7;
}
.cls-7 {
  fill: #3f4d79;
}
.cls-8 {
  fill: #ffcfc9;
}
.cls-9 {
  fill: #6562cf;
}
.cls-10 {
  fill: #8382d8;
}
.cls-12 {
  stroke: #8382d8;
}
.cls-12,
.cls-13,
.cls-15 {
  stroke-linecap: round;
}
.cls-12,
.cls-16,
.cls-17,
.cls-19 {
  stroke-width: 6px;
}
.cls-13 {
  stroke: #f7b3af;
}
.cls-13,
.cls-14 {
  stroke-width: 4px;
}
.cls-14 {
  stroke: #3f4d79;
}
.cls-16 {
  fill: #d1d0ff;
}
.cls-16,
.cls-19 {
  stroke-linejoin: round;
}
.cls-18 {
  fill: #e2e2ff;
}
</style>
