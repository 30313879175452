<template>
  <div
    class="flex min-h-screen flex-col justify-center bg-white py-12 sm:px-6 lg:px-8"
  >
    <div class="bg-white-50 flex justify-center">
      <div class="left">
        <IllustrationFour />
      </div>
      <div class="right">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <form class="space-y-6 pt-6 text-left">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input
                id="email-address"
                name="email"
                type="email"
                v-model="username"
                autocomplete="email"
                required
                class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Email address"
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div class="mt-1">
              <el-input
                v-model="password"
                show-password
                autocomplete="current-password"
                placeholder="strong password"
              ></el-input>
            </div>
          </div>

          <div v-if="alert.show">
            <el-alert title="" type="error" :description="alert.desc" show-icon>
            </el-alert>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <a
                href="#"
                @click.prevent="goToRegistration"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Register
              </a>
            </div>

            <div class="text-base">
              <a
                href="#"
                @click.prevent="goToForgotPassword"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </a>
            </div>
          </div>
        </form>
        <div class="flex flex-row-reverse pt-4">
          <button
            @click="onSubmit"
            v-loading.fullscreen.lock="fullscreenLoading"
            class="focus:outline-none flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
          >
            Sign in
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
import qs from 'qs';
import axios from 'axios';

import { mapGetters } from 'vuex';

import IllustrationFour from '@/common/components/svg/IllustrationFour';

export default {
  name: 'Login',
  components: {
    IllustrationFour,
  },
  data() {
    return {
      fullscreenLoading: false,
      username: null,
      password: null,
      alert: {
        show: false,
        desc: '',
      },
    };
  },

  computed: {
    ...mapGetters('auth', {
      setToken: null,
      isAuthenticated: false,
    }),
  },
  methods: {
    async onSubmit() {
      const url = `${process.env.VUE_APP_API_LOCATION}/connect/token`.replace(
        '/api/',
        ''
      );

      const data = {
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        grant_type: process.env.VUE_APP_GRANT_TYPE,
        scope: "offline_access",
        username: this.username,
        password: this.password,
      };

      let headers = {
        accept: '*/*',
        'content-type': 'application/x-www-form-urlencoded',
      };

      try {
        this.fullscreenLoading = true;
        let results = await axios.post(url, qs.stringify(data), headers);

        /* Get & Set User */
        const header = {
          Authorization: `Bearer ${results.data.access_token}`,
        };

        axios
          .get(`${process.env.VUE_APP_API_LOCATION}` + `Users`, {
            headers: header,
          })
          .then((user) => {
            console.log({ user });
            /* Set User */
            this.$store.dispatch('user/setUser', user.data.Item);
            /* Set Token */
            this.$store.dispatch('auth/setToken', results.data.access_token);
            this.$store.dispatch('auth/setRefreshToken', results.data.refresh_token);
            this.$store.dispatch('auth/setIsAuthenticated', true);

            this.$router.push('/');
            this.fullscreenLoading = false;
          })
          .catch((error) => {
            console.log(error);
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              this.$notify({
                title: 'Oops',
                message: error.response.data.Message,
                type: 'error',
              });
            } else if (error.request) {
              // The request was made but no response was received

            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            // this.$notify({
            //   title: 'Warning',
            //   message: error.message,
            //   type: 'error',
            // });
            this.fullscreenLoading = false;
          });
      } catch (error) {
        this.fullscreenLoading = false;
        this.alert.show = true;
        this.alert.desc = error.response.data.error_description.replace(
          '_',
          ' '
        );
      }
    },

    goToForgotPassword() {
      this.$router.push({ name: 'forgot-password' });
    },

    goToRegistration() {
      this.$router.push({ name: 'registration' });
    },
  },
};
</script>

<style scoped>
.login--wrapper {
  border: 1px solid #ccc;
  padding: 23px;
  border-radius: 20px;
  background-color: #fff;
}

.left {
  display: flex;
  flex-direction: column;
  /* border: 1px solid rgba(249, 250, 251, 1);
  border-radius: 0.375rem; */
  margin-right: 3rem;
  padding: 4rem;
}

.right {
  width: 30%;
  padding: 2rem;
}
</style>
